import {getShortList, sendFavorite} from "@/modules/favorite/helpers";
import {showModalSignIn} from "@/pages/auth/helpers";

export default (
  id: string,
  type = "property",
  isFavorite = false as boolean) =>
  ({
    isFavorite: isFavorite,
    clicked: false,
    shortlistOpen: false,
    shortlists: [],
    loadingShortlist: false,
    nameShortlist: "",
    action: "",
    async onClickFavorite() {
      const token = localStorage.getItem('auth_token') ?? null;
      if (token) {
        this.clicked = true;
        this.action = ""; // clear action
        this.loadingShortlist = false;
        let action = this.isFavorite ? "remove" : "add";
        //console.log("Favorite",this.isFavorite,action)
        let favorite = await sendFavorite({
          type: type,
          id: id,
          shortlist_ids: [],
          action: action
        }) as any;

        this.isFavorite = !this.isFavorite;
        this.action = favorite?.data?.action;
        this.nameShortlist = favorite?.data?.name;
        if (this.action === 'added'
          && this.shortlists.length === 0) {
          this.loadingShortlist = true;
          this.shortlists = await getShortList() ?? [];
          this.loadingShortlist = false;
        }
        //console.log("favorite", favorite)
      } else {
        /* open form modal */
        showModalSignIn(true);
        // Global variable alpine in auth page
      }
    },
    async addToShortlist(shortlist_ids: string) {
      this.loadingShortlist = true;
      this.shortlistOpen = false;
      this.action = ""; // clear action
      let favorite = await sendFavorite({
        type: type,
        id: id,
        shortlist_ids: [shortlist_ids],
        action: "add"
      }) as any;
      this.action = "added";
      this.loadingShortlist = false;
      this.nameShortlist = favorite?.data?.name;
    }
  });
